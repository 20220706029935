















import { Component, Prop, Vue } from 'vue-property-decorator';
import sdk from '@/sdk/InstoreSdk';
import { Message } from 'element-ui';

// banner组件通信传值设定：
// initOptions：swiper初始化相关参数
// page：getHeaderBanner的传参
// initSwiper：是否为轮播，默认非轮播
// data：自定义banner数据

@Component
export default class InsBanner extends Vue {
  @Prop() private initOptions!: object;
  @Prop({ default: '' }) private page!: string;
  @Prop({ default: false }) private initSwiper!: boolean;
  @Prop() private data!: any;
  bannerList: object[] = [];
  bannerImg: string = '';

  swiperOption: object = {
    autoplay: {
      disableOnInteraction: true
    }
    // pagination: {
    //   el: '.swiper-pagination',
    //   clickable: true
    // },
    // navigation: {
    //   nextEl: '.swiper-button-next',
    //   prevEl: '.swiper-button-prev'
    // }
  };
  getBanner () {
    let _this = this;

    sdk.api.promotion.getHeaderBanner(this.page).then(
      function (data) {
        _this.bannerList = data;
      },
      function (data) {
        Message({
          message: data,
          type: 'error'
        });
      }
    );
  }

  created () {
    if (this.initOptions) {
      this.swiperOption = this.initOptions;
    }
  }

  mounted () {
    // if (this.bannerList.length === 1) {
    // 只有1个slide，swiper会失效且隐藏切换按钮
    // this.swiper.destroy(false);
    // }

    if (this.page) {
      this.getBanner();
    } else {
      if (this.initSwiper) {
        this.bannerList = this.data;
      } else {
        if (typeof (this.data) === 'object') {
          this.bannerImg = this.data[0].Image;
        } else if (typeof (this.data) === 'string') {
          this.bannerImg = this.data;
        }
      }
    }
  }
}
